import React from "react";
import it_IT from "antd/es/locale/it_IT";
import {ConfigProvider} from "antd";
import "../style/style.less";
import {EventProvider} from "../hooks/useEventValue";
import apolloClient from "../apolloClient";
import {ApolloProvider} from '@apollo/client';
import EventInitializer from "./EventInitializer";
import {initialState, reducer} from "../reducer";

const RootWrapper = ({ element }) => {

  return (
    <ConfigProvider locale={it_IT}>
      <ApolloProvider client={apolloClient}>
        <EventProvider initialState={initialState} reducer={reducer}>
          <EventInitializer/>
          {element}
        </EventProvider>
      </ApolloProvider>
    </ConfigProvider>
  )
}


export default RootWrapper
