import { Modal } from "antd"
import _ from "lodash"
import urlParser from "js-video-url-parser"
import { useLocation } from "@reach/router"

export const devLog = (data) => {
  if (process.env.NODE_ENV === "development") {
    console.log(data)
  }
}


export const uiHandleError = (error) => {
  Modal.error({
    title: "Attenzione",
    content: error.message
  })

  if (process.env.NODE_ENV === "development") {
    console.error(error)
  }
}

const mapVideoGallery = (rawData) => {
  return rawData.map((item) => {
      if (!item) {
        return null
      }

      return item.videoGalleriaUrl
    }
  )
}

const mapFileList = (rawData) => {
  return rawData.map((item) => {
    if (!item) {
      return null
    }

    let file = item.file
    return {
      id: file.databaseId,
      filename: item.nomeFile,
      url: file.localFile.publicURL
    }
  })
}

const mapImageList = (rawData) => {
  return rawData.map((item) => {
    if (!item) {
      return null
    }

    return {
      url: item.immagineGalleriaSingola.localFile.childImageSharp.fluid.src
    }
  })
}

const mapPost = (rawData) => {
  return rawData.map((item) => {
    if (!item) {
      return null
    }

    return {
      databaseId: item.databaseId,
      title: item.title,
      url: item.link
    }
  })
}

const mapActions = (data) => {
  const result = {
    click: data.tipoArea,
    calendar_type: data.opzioniCalendario,
    calendar_option: data.opzioniCalendarioCustom,
    chat_option_custom: data.opzioniChatCustom,
    chat_option_1_to_1: data.opzioniChat1to1,
    chat_type: data.opzioniChat,
    action_id: data.actionId,
    external_link: data.opzioniLinkBrowser,
    file_list: data.opzioniFileMultiplo ? mapFileList(data.opzioniFileMultiplo) : [],
    gallery_image_list: data.opzioniImmaginiGalleria ? mapImageList(data.opzioniImmaginiGalleria) : [],
    gallery_video_list: data.opzioniVideoGalleria ? mapVideoGallery(data.opzioniVideoGalleria) : [],
    leaderboard_option: data.opzioniLeaderboard,
    selected_post_list: data.opzioniArchivioArticoli ? mapPost(data.opzioniArchivioArticoli) : [],
    selected_room: data.opzioniLinkInterno ? data.opzioniLinkInterno.slug : null,
    video_call_option: data.opzioniVideochiamata,
    webinar_option: data.opzioniWebinar
  }

  if (data.opzioniLinkInterno) {
    result.selected_room_link = data.opzioniLinkInterno.link
  }

  return result
}

export const mapAcfObjToImageMapPro = (acfData) => {
  let result = JSON.parse(acfData.roomSettings)
  if (!result) {
    // create empty
    let default_settings = window.jQuery.imageMapProEditorDefaults
    result = _.cloneDeep(default_settings)
    result.id = Math.round(Math.random() * 10000) + 1
  }

  if (acfData.background) {
    result.image = {
      url: {
        url: acfData.background.localFile.childImageSharp.fixed.src
      }
    }
  }

  result.spots = []

  if (acfData.elencoAree) {
    acfData.elencoAree.map(function(area) {
      let spot = JSON.parse(area.hotspotArea)

      // TODO parse actions
      spot.actions = mapActions(area)

      if (area.immagineArea) {
        if (!spot.default_style) {
          spot.default_style = {}
        }
        spot.default_style.background_type = "image"
        spot.default_style.background_image_url = {
          "url": area.immagineArea.localFile.childImageSharp.fluid.src
        }

        spot.mouseover_style = spot.default_style
      }

      spot.tooltip_content = JSON.parse(area.tooltipArea)

      result.spots.push(spot)
    })
  }

  return result
}

export const getEmbedUrl = (url) => {
  const videoInfo = urlParser.parse(url)
  if (!videoInfo) {
    return url
  }

  return urlParser.create({
    videoInfo: videoInfo,
    format: "embed"
  })
}


export const makeIrcUrl = ({ location, user_id, nickname, channels, active_buffer, restrict_mode = 1, layout = "compact" }) => {
  let sanitizeNickname = nickname.replace(/\s+/g, "_")
  if (!sanitizeNickname) {
    sanitizeNickname = "Ospite" + (Math.random() * 1000000).toString().split(".")[0]
  }

  let channelsParam = channels.map(chan => (`&channels[]=${chan.replace("#", "%23")}`)).join()

  return `${location.origin}/chat/irc/?user_id=${user_id}&nickname=${sanitizeNickname}${channelsParam}&active_buffer=${active_buffer.replace("#", "%23")}&restrict_mode=${restrict_mode}&layout=${layout}`
}

export const getUserNickname = () => {
  let username = "Mario    Rossi"

  return username
}

export const getUserId = () => {
  return 0
}

export const sanitizeChannelName = (str) => {
  return str.replace(/\s+/g, "_").replace("#", "%23")
}

export const usePageSlug = () => {
  const location = useLocation()
  return location.pathname.split("/")[1]
}
