import React, {createContext, useContext, useReducer} from "react";

export const EventContext = createContext();

export const EventProvider = ({children, reducer, initialState}) => (
    <EventContext.Provider value={useReducer(reducer, initialState)}>{children}</EventContext.Provider>
);

export const useEventValue = () => useContext(EventContext);

