// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-coming-soon-js": () => import("./../../../src/pages/coming-soon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sign-in-js": () => import("./../../../src/pages/sign-in.js" /* webpackChunkName: "component---src-pages-sign-in-js" */),
  "component---src-pages-sign-out-js": () => import("./../../../src/pages/sign-out.js" /* webpackChunkName: "component---src-pages-sign-out-js" */),
  "component---src-pages-sign-up-js": () => import("./../../../src/pages/sign-up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-pages-sponsor-stand-js": () => import("./../../../src/pages/sponsor_stand.js" /* webpackChunkName: "component---src-pages-sponsor-stand-js" */),
  "component---src-pages-welcome-js": () => import("./../../../src/pages/welcome.js" /* webpackChunkName: "component---src-pages-welcome-js" */),
  "component---src-templates-sponsor-stand-js": () => import("./../../../src/templates/SponsorStand.js" /* webpackChunkName: "component---src-templates-sponsor-stand-js" */),
  "component---src-templates-virtual-room-js": () => import("./../../../src/templates/VirtualRoom.js" /* webpackChunkName: "component---src-templates-virtual-room-js" */)
}

