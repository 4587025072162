import fetch from 'cross-fetch';
import {ApolloClient, createHttpLink, InMemoryCache} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {USER_TOKEN} from "./enums/LocalStorageKeys";

const httpLink = createHttpLink({
    uri: process.env.MAGENTO_GRAPHQL_URL,
    fetch,
});

const authLink = setContext((_, {headers}) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem(USER_TOKEN);

    const store = {
        store: process.env.MAGENTO_STORE_VIEW_CODE || 'default',
        "content-currency": process.env.MAGENTO_CURRENCY || 'EUR',
    };

    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            ...store,
            authorization: token ? `Bearer ${token}` : "",
        },
    };
});


const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
});

export default apolloClient;
