import React, { useEffect } from "react"
import { useEventValue } from "../hooks/useEventValue"
import { navigate } from "gatsby"


const EventInitializer = () => {
  const [{ eventOpened, registrationRequired, user, isLoadingUser }, dispatch] = useEventValue()

  useEffect(() => {
    const fetchUserData = async () => {
      // TODO loadig user data

      if (registrationRequired) {
        if (!user) {
          navigate("/welcome", { replace: true })
        }
      }

      if (!eventOpened) {
        navigate("/coming-soon", { replace: true })
      }
    }

    fetchUserData()
  }, [])

  return null
}

export default EventInitializer
