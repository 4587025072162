import { devLog } from "./utils"
import { SET_USER, SIGN_OUT } from "./enums/ReducerTypes"
import { USER_TOKEN } from "./enums/LocalStorageKeys"
import { defaultTheme } from "./theme"

const initialState = {
  theme: defaultTheme,
  user: null,
  isLoadingUser: false,
  eventOpened: true,
  registrationRequired: false
}

const reducer = (state, action) => {
  devLog({ action })

  switch (action.type) {

    case SET_USER:
      return {
        ...state,
        customer: action.user,
        isLoadingUser: false
      }

    case SIGN_OUT:
      localStorage.removeItem(USER_TOKEN)
      return {
        ...state,
        user: null,
        isLoadingUser: false
      }

    default:
      return state
  }
}

export {
  reducer,
  initialState
}
